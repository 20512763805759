/*
 * VARIABLES
 *
 * Global sizes and colors that can be used in every component
 *
 */

/* ============================================================ *\
|* Colors
\* ============================================================ */

$black:					#000;
$white:					#FFF;
$light-gray:			#CCC;
$dark-gray:				#767676;
$dark-gray-shade:		#545454;
$gold:					#F89B39;
$navy:					#1E4784;

// Insert brand color values

/* ============================================================ *\
|* Breakpoints
\* ============================================================ */

$bp-mobile:	 			480px;
$bp-mobile-large:		768px;
$bp-tablet:				1024px;
$bp-monitor-small:		1240px;
$bp-monitor-medium:		1440px;

:export {
  bpMobile: $bp-mobile;
  bpMobileLarge: $bp-mobile-large;
  bpTablet: $bp-tablet;
  bpMonitorSmall: $bp-monitor-small;
  bpMonitorMedium: $bp-monitor-medium;
}