/*
 * Typography
 * 
 * Website-wide text styling
 *
 */


* {
	font-size: inherit;
	font-weight: inherit;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {	
	color: $navy;
	text-decoration: none;
}


 h1,  h2,  h3,  h4,  h5,  h6, blockquote,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin: 0px;
	font-weight: normal;
}

p {
	margin: 0px;
}

p + p,
li + li {
	margin-top: .5em;
}

strong {
	font-weight: normal;
}

h1, .h1 		{font-size: 36pt}
h2, .h2 		{font-size: 28pt}
h3, .h3 		{font-size: 20pt}
h4, .h4 		{font-size: 18pt}
h5, .h5 		{font-size: 16pt}
h6, .h6 		{font-size: 14pt}
blockquote		{font-size: 28pt}
p, .p, body 	{font-size: 13pt}

h1, .h1 		{line-height: 1.3em}
h2, .h2 		{line-height: 1.3em}
h3, .h3 		{line-height: 1.3em}
h4, .h4 		{line-height: 1.3em}
h5, .h5 		{line-height: 1.3em}
h6, .h6 		{line-height: 1.3em}
blockquote		{line-height: 1.3em}
p, .p, body 	{line-height: 1.3em}


@media screen and (min-width: $bp-mobile-large){
	h1, .h1 	{font-size: 42pt}
	h2, .h2 	{font-size: 32pt}
	h3, .h3 	{font-size: 24pt}
	h4, .h4		{font-size: 20pt}
	h5, .h5 	{font-size: 18pt}
	h6, .h6 	{font-size: 15pt}
	blockquote	{font-size: 32pt}
	p, .p, body {font-size: 13pt}

	h1, .h1 	{line-height: 1.3em}
	h2, .h2 	{line-height: 1.3em}
	h3, .h3 	{line-height: 1.3em}
	h4, .h4 	{line-height: 1.3em}
	h5, .h5 	{line-height: 1.3em}
	h6, .h6 	{line-height: 1.3em}
	blockquote	{line-height: 1.3em}
	p, .p, body {line-height: 1.3em}
}

.colonial {
	// font-family: "Akzidenz Grotesk Condensed";
	font-family: "Akzidenz Grotesk", helvetica, arial, sans-serif;

	 h1,  h2,  h3,  h4,  h5,  h6, blockquote,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		font-family: "Akzidenz Grotesk Condensed";
		text-transform: uppercase;
	}

	p {
		color: $dark-gray-shade
	}
} 

.rethink {
	font-family: "Akzidenz Grotesk", helvetica, arial, sans-serif;
	color: $dark-gray-shade;

	 h1,  h2,  h3,  h4,  h5,  h6, blockquote,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		font-family: "British Inserat", helvetica, arial, sans-serif;
		text-transform: uppercase;
		color: $black;
	}
}
