/**
 * Input
 */

.button {
	font-family: "British Inserat", helvetica, arial, sans-serif;
	font-size: 16pt;
	display: inline-block;
	padding: 1em 2em .8em;
	background-color: $black;
	color: $white;
	text-transform: uppercase;
	letter-spacing: .1em;
	cursor: pointer;
	line-height: 1;
}