/**
 *
 * Base
 *
 *
**/

html {
	box-sizing: border-box;
}

body {
	overflow-x: hidden;
	margin: 0px;
	padding: 0px;
	border-style: none;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

* {
	fill: inherit;
	stroke: inherit;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

ul {
	padding: 0px;
}

ul > li {
	list-style: none;
}

cite {
	display: inline-block;
	font-style: normal;
}
