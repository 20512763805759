/**
 * Resources
 */

@import "style/_variables.scss";

.page {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 128px 6% 5vw 6%;
}

.page ul {
	margin: 0px;
}

.page h3 {
	margin-bottom: .4em;
}

.container {
	max-width: 100%;
	width: $bp-tablet;
	margin: auto;
}

.about,
.acknowledgements,
.category {
	margin-bottom: 2em;
}

.complete-container {
	text-align: right;
}