/*
 *  INDEX
 *
 *  Import all global variables, helpers, core styles and components
 *
 */

@charset "utf-8/index";

@font-face {
    font-family: "Oswald";
    src: url("../content/fonts/Oswald-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Akzidenz Grotesk";
    src: url("../content/fonts/akzidenz_grotesk_regular.ttf") format("truetype");
}

@font-face {
    font-family: "Akzidenz Grotesk Condensed";
    src: url("../content/fonts/akzidenz_grotesk_condensed.ttf") format("truetype");
}

@font-face {
	font-family: "British Inserat";
	src: url("../content/fonts/british_inserat.ttf") format("truetype");
}


// Import Variables
@import "./variables";

// Reset element styles
@import "./base";

// Set the general typographic styling for the project
@import "./typography";

// Set the general input and UI styling for the project
@import "./input";
